import Landing from "../components/Home/Landing";
import AboutSec from "../components/Home/AboutSec";
import Testimonials from "../components/Home/Testimonials";
import "../style/Home/Home.scss";
import "../style/Home/Breakpoints.scss";
import useDocumentTitle from "../lib/useDocumentTitle";
import Skills from "../components/Home/Skills";
import Services from "../components/Home/Services";
import { lazy } from "react";

const Designs = lazy(() => import("../components/Home/Designs"));

function Home() {
  useDocumentTitle("Web Agency");

  return (
    <main id="home-page">
      <Landing />
      <AboutSec />
      <Services />
      <Skills />
      <Designs />
      <Testimonials />
    </main>
  );
}

export default Home;
