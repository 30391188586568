const ToggleBTN = ({ value, setValue }) => {
  return (
    <div className="toggle-btn--container">
      <span className="toggle--btn-status">{value ? "On" : "Off"}</span>
      <button
        onClick={setValue}
        className={`toggle-btn ${value && "toggle-btn-on"}`}
      >
        <div className="toggle-btn--circle"></div>
      </button>
    </div>
  );
};

export default ToggleBTN;
