import { memo, useCallback } from 'react';

const LandingImg = memo(({ first, Icon, text, title, img }) => {
  const onHover = useCallback((event) => {
    if (!event.currentTarget.classList.contains("landing__img--open")) {
      const currentOpenElem = document.querySelector(".landing__img--open");
      currentOpenElem?.classList.remove("landing__img--open");
      event.currentTarget.classList.add("landing__img--open");
    }
  }, []);

  return (
    <div
      className={`landing__img--wrapper ${first ? "landing__img--open" : ""}`}
      onMouseEnter={onHover}
    >
      <img src={img} alt={title} className="landing__img" />
      <div className="landing__img--info-box">
        {Icon && <Icon className="landing__img--info-icon" />}
        <div className="landing__img--info-container">
          <h1 className="landing__img--info-title">{title}</h1>
          <span className="landing__img--info-text">{text}</span>
        </div>
      </div>
    </div>
  );
});

LandingImg.displayName = 'LandingImg';
export default LandingImg;