const Button = ({ onClick, primary, children }) => {
  return (
    <button
      onClick={onClick || null}
      className={`btn click ${primary && "primcolor-bg"}`}
    >
      {children}
    </button>
  );
};

export default Button;
