import { Link } from "react-router-dom";
import Button from "../components/UI/Button";
import ToggleBTN from "./UI/ToggleBTN";
import useCookies from "../hooks/useCookies";

const ManageModal = ({
  handleConsentChange,
  consent,
  savePreferences,
  onClose,
}) => {
  const handleChange = (value) => {
    handleConsentChange(value, !consent[value]);
  };

  return (
    <div className="modal">
      <div className="modal--container">
        <h1 className="modal--title">Manage Cookies</h1>
        <div className="cookies--container">
          <div className="cookie--setting">
            <div className="cookie__setting-top">
              <span className="cookie__setting--title">Necessary</span>
              <span className="cookie__setting--always">Always on</span>
            </div>
            <div className="cookie__setting-bottom">
              <p className="cookie__setting--para">
                Required to enable core functionality and to remember user
                preferences and choices, such as customized settings
              </p>
            </div>
          </div>
          <div className="cookie--setting">
            <div className="cookie__setting-top">
              <span className="cookie__setting--title">Analytics</span>
              <ToggleBTN
                value={consent["analytics"]}
                setValue={() => handleChange("analytics")}
              />
            </div>
            <div className="cookie__setting-bottom">
              <p className="cookie__setting--para">
                These cookies provide quantitative measures of website visitors.
                With the usage of these cookies we are able to count visits and
                traffic sources to improve the performance of our site.
              </p>
            </div>
          </div>
        </div>
        <div className="buttons--container">
          <Button onClick={savePreferences} primary>
            Save Preferences
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};

const CookieConsent = () => {
  const {
    showCookiesBanner,
    handleConsentChange,
    handleAccept,
    handleAcceptAll,
    handleReject,
    consent,
    editPref,
    setEditPref,
  } = useCookies();

  if (!showCookiesBanner)
    return (
      editPref && (
        <ManageModal
          handleConsentChange={handleConsentChange}
          consent={consent}
          savePreferences={handleAccept}
          onClose={() => setEditPref(false)}
        />
      )
    );

  return (
    <>
      {editPref && (
        <ManageModal
          handleConsentChange={handleConsentChange}
          consent={consent}
          savePreferences={handleAccept}
          onClose={() => setEditPref(false)}
        />
      )}
      <div className="cookie--banner">
        <div className="cookie--top">
          <span className="cookie--title">We use cookies</span>
          <Link to={"/privacy-policy"} className="cookie--link">
            Privacy Policy
          </Link>
        </div>
        <p className="cookie__message">
          We use cookies to enhance your experience. By using our website, you
          agree to the use of essential cookies. You can choose to accept
          additional cookies for analytics and marketing purposes.{" "}
          <Link to="/cookie-policy">Learn More</Link>
        </p>
        <div className="cookie--buttons">
          <Button onClick={handleAcceptAll} primary>
            Accept All
          </Button>
          <Button onClick={handleReject} primary>
            Decline All
          </Button>
          <Button onClick={() => setEditPref(true)}>Manage Preferences</Button>
        </div>
      </div>
    </>
  );
};

export default CookieConsent;
