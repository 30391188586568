import React, { useEffect, useRef, memo } from "react";
import { FaCode, FaHeadset, FaSearch } from "react-icons/fa";
import { IoMdColorPalette } from "react-icons/io";
import DOTS from "vanta/src/vanta.dots";
import Scroll from "../UI/Scroll";
import ArrowLink from "../UI/ArrowLink";
import LandingImg from "./UI/LandingImg";
import designImg from "../../assets/Design.jpeg";

// Move constants outside component to prevent recreating on each render
const VANTA_CONFIG = {
  mouseControls: false,
  touchControls: false,
  gyroControls: false,
  minHeight: 200.0,
  minWidth: 200.0,
  scale: 1.0,
  scaleMobile: 1.0,
  color: 0x7443cd,
  color2: 0xffffff,
  backgroundColor: 0x232325,
  showLines: false,
};

const LANDING_IMAGES = [
  {
    first: true,
    img: designImg,
    title: "Innovative Design",
    text: "Cutting-edge web design",
    Icon: IoMdColorPalette,
  },
  {
    img: "https://petrotechinc.com/wp-content/uploads/2019/06/iStock-1356364268.jpg",
    title: "Robust Development",
    text: "Reliable web development",
    Icon: FaCode,
  },
  {
    img: "https://www.parkregisbirmingham.co.uk/wp-content/uploads/2016/10/business-meeting-birmingham.jpg",
    title: "SEO Optimzation",
    text: "Boost your visibility",
    Icon: FaSearch,
  },
  {
    img: "https://www.ringcentral.com/content/dam/rc-www/en_us/images/content/seo/customer-support-team/Call-queue-hero-png-rendition.webp",
    title: "24/7 Support",
    text: "Always here to help",
    Icon: FaHeadset,
  },
];

const Landing = memo(function Landing() {
  const vantaRef = useRef(null);

  useEffect(() => {
    if (vantaRef.current) {
      vantaRef.current.destroy();
    }
    
    vantaRef.current = DOTS({
      el: "#landing--container",
      ...VANTA_CONFIG
    });

    return () => {
      if (vantaRef.current) {
        vantaRef.current.destroy();
      }
    };
  }, []);

  return (
    <section id="landing">
      <div data-aos="fade-in" id="landing--container">
        <div className="container">
          <div className="row">
            <div data-aos="fade-right" className="landing--left left">
              <span className="landing__top-text">
                INNOVATE, CREATE, SUCCEED
              </span>
              <h1 className="landing--title title">
                Welcome to <span className="primcolor">AG Web Studios</span>
              </h1>
              <span className="landing--para para">
                Discover exceptional web services tailored to elevate your
                online presence. Join us in shaping the future of the web.
              </span>
              <ArrowLink destination={"/contact"} text="Let's Chat!" />
            </div>
            <div data-aos="fade-left" className="landing--right right">
              {LANDING_IMAGES.map((props) => (
                <LandingImg key={props.title} {...props} />
              ))}
            </div>
          </div>
        </div>
        <Scroll anchor="aboutsec" />
      </div>
    </section>
  );
});

export default Landing;
